<template>
  <div class="section section--wikipedia" v-if="article">
    <h2 v-if="showTitle">About {{ decodeURIComponent(articleTitle) }}</h2>
    <img v-if="article.originalimage" :src="article.originalimage.source" />
    <div v-html="article.extract_html" />
    <p>
      <a :href="`https://en.wikipedia.org/wiki/${articleTitle}`"
        >View on Wikipedia</a
      >
    </p>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: {
    articleTitle: { type: String },
    showTitle: { type: Boolean, default: false }
  },
  data() {
    return {
      article: null
    };
  },
  methods: {
    async loadArticle() {
      this.article = null;
      if (!this.articleTitle) {
        return;
      }
      try {
        const response = await axios.get(
          `https://en.wikipedia.org/api/rest_v1/page/summary/${this.articleTitle}`
        );
        this.article = response.data;
      } catch (error) {
        console.log(error);
      }
    }
  },
  created() {
    this.loadArticle();
  },
  watch: {
    articleTitle() {
      this.loadArticle();
    }
  }
};
</script>
